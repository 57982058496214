<template>
  <div>
    <Header />
    <Breadcrumb :title="'Report User'"/>
    <div class="information-ul">
      <div class="container">
        <el-row :gutter="20">
          <el-col :xs="12" :sm="6" :md="8" :lg="8">
            <div class="sidebar-bg">
              <div class="page-links">
                <ul>
                  <li><a href="/#/safetycenter" class="active"> Safety Center</a></li>
                  <li><a href="/#/datingincovid"> Dating in the time of Corona Virus</a></li>
                  <li><a href="/#/report_user"> Report a Users</a></li>
                  <li><a href="/#/safety-tips"> View our Safety Tips</a></li>
                  <li><a href="/#/additional-resources"> View additional resources</a></li>
                </ul>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="14" :md="16" :lg="16">
            <el-card>
              <div class="card-header">
                <div class="page-title">&nbsp; Send Report to Admin!</div><br>
              </div>
              <el-form ref="form" :model="form" class="contact-form">
                <el-form-item label="User Name">
                  <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="Message">
                  <el-input type="textarea" v-model="form.message"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onSubmit">Submit</el-button>
                  <router-link
                    :to="{ name: 'frontend'}"
                    class="c-report-user__cancel"
                  >
                    <el-button>Cancel</el-button>
                  </router-link>
                </el-form-item>
              </el-form>
            </el-card>
          </el-col>
        </el-row>

      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Breadcrumb from '@/views/user/_components/Breadcrumb'
import Footer from '@/components/Footer'

export default {
  components: {
    Header,
    Footer,
    Breadcrumb
  },

  data () {
    return {
      form: {
      }
    }
  },

  methods: {
    onSubmit () {
    }
  },

  name: 'ReportUser'
}
</script>
<style lang="scss">
.c-report-user {
  &__cancel {
    margin-left: 1rem;
  }

  .el-pages {
    margin: 0 auto;
    padding: 40px 0 0 0;
  }
}
.information-ul {
  margin: 0;
  padding: 40px 0;
  background: #f3f2ef;
}

.information-ul .page-title {
  font-size: 24px !important;
  padding-top: 10px;
}

.information-ul p{
  padding: 10px 0;
  line-height: 24px;
}

.sidebar-bg{
  background: #fff;
  padding-bottom: 40px;
}

.page-links{
  margin: 0;
  padding:10px 0 0 0;
}

.page-links ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

.page-links li{
  margin: 0 5px;
  padding: 20px 0 20px 20px;
  list-style: none;
  font-size: 17px;
  border-bottom: 1px solid #ccc;
}

.page-links li a{
  margin: 0;
  padding: 0;
  list-style: none;
  transition: all 0.3s ease-in 0s;
}

.page-links li a:hover{
  color: #003993;
  transition: all 0.3s ease-in 0s;
}

.page-links li a.active{
  color: #003993;
  transition: all 0.3s ease-in 0s;
}
</style>
